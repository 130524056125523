// ==UserScript==
// @name Imgur Direct Video
// @description Redirects Imgur "GIFV" pages to the video for better viewing experience.
// @version 1
// @namespace https://userscripts.kevincox.ca
// @match https://i.imgur.com/*.gifv
// @run-at document-start
// @grant none
// ==/UserScript==

document.location.replace(document.location.href.replace(/\.gifv$/, ".mp4"))
